<app-overlay [loading]="loading" appFloatingElement (close)="onClose()">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose()">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button class="next-button" [disabled]="invoiceForm.invalid" (click)="generateInvoice()">
      {{ 'economy.download_invoice' | translate }}
    </app-button>
  </div>

  <div class="overlay__header">
    <h2>{{ 'economy.generate_invoice_for' | translate }} {{ user.first_name }} {{ user.last_name }}</h2>
    <p class="sub-header ns-text small">{{ paymentName }}</p>
  </div>

  <div class="overlay__body overlay__body--default-width">
    <app-info-block
      *ngIf="paymentType === PaymentTypes.MembershipFee && priceCategories && priceCategories.length > 1"
      type="warning"
      [title]="'economy.multiple_price_categories' | translate"
    >
      {{ 'economy.multiple_price_categories_description' | translate }}
    </app-info-block>

    <div class="ns-description">
      <p class="ns-text small">{{ 'economy.new_invoice_description' | translate }}</p>
      <p *ngIf="paymentType !== PaymentTypes.EventPayment" class="ns-text small">
        {{ 'economy.new_invoice_description_2' | translate }}
      </p>
    </div>

    <form [formGroup]="invoiceForm" class="ns-form ns-light">
      <mat-form-field>
        <mat-label>{{ 'economy.payment_deadline' | translate }}</mat-label>
        <input matInput type="date" formControlName="dueDate" />
        <mat-icon matPrefix>calendar_today</mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'economy.bank_account_number' | translate }}</mat-label>
        <input
          matInput
          formControlName="bankAccountNumber"
          [minlength]="Constants.bankAccountNumberLength"
          [maxlength]="Constants.bankAccountNumberLength"
          pattern="^\d+$"
          autocomplete="off"
        >
        <mat-hint>
          {{ 'economy.account_length_hint' | translate: { number: Constants.bankAccountNumberLength } }}
        </mat-hint>
      </mat-form-field>

      <div *ngIf="paymentType === PaymentTypes.MembershipFee" class="ns-membership-fee-price">
        <p class="ns-price-categories-header">
          <span class="ns-subtitle">{{ 'economy.price_options' | translate }}</span>
          <app-loader *ngIf="isLoadingPriceCategories" [static]="true" [size]="20"></app-loader>
        </p>

        <app-expand-y *ngIf="priceCategories" class="ns-price-categories-wrapper">
          <div class="ns-price-categories-description">
            <p *ngIf="priceCategories.length === 1" class="ns-text small">
              {{
                'payment.user_payment_price_categories_description'
                  | translate : { firstName: user.first_name, lastName: user.last_name }
              }}
            </p>
            <p *ngIf="priceCategories.length > 1" class="ns-text small">
              {{
                'payment.user_payment_price_categories_description_2'
                  | translate : { firstName: user.first_name, lastName: user.last_name }
              }}
            </p>
            <p class="ns-text small">{{ 'economy.nif_minimum_price_description' | translate }}</p>
          </div>

          <div class="ns-price-categories" [class.ns-disabled]="isSettingCustomPrice">
            <div *ngFor="let category of priceCategories" class="ns-price-category">
              <div class="ns-category-name-wrapper ns-subtitle">
                <span class="ns-category-name">{{ category.name }}</span>
                <span class="ns-price">{{ category.gross_amount | currency }}</span>
              </div>
              <div class="ns-category-details">
                <div class="ns-category-restrictions">
                  <p
                    *ngIf="
                      (category.min_age | isNumber) &&
                      (category.max_age | isNumber) &&
                      category.min_age !== category.max_age
                    "
                  >
                    {{ 'economy.from_age_to_age' | translate : { ageFrom: category.min_age, ageTo: category.max_age } }}
                  </p>
                  <p
                    *ngIf="
                      (category.min_age | isNumber) &&
                      (category.max_age | isNumber) &&
                      category.min_age === category.max_age
                    "
                  >
                    {{ 'economy.for_age' | translate : { age: category.min_age } }}
                  </p>
                  <p *ngIf="(category.min_age | isNumber) && !(category.max_age | isNumber)">
                    <ng-container *ngIf="category.min_age === minAge">
                      {{ 'economy.all_ages' | translate }}
                    </ng-container>
                    <ng-container *ngIf="category.min_age !== minAge">
                      {{ 'economy.above_age' | translate : { age: category.min_age - 1 } }}
                    </ng-container>
                  </p>
                  <p *ngIf="!(category.min_age | isNumber) && (category.max_age | isNumber)">
                    <ng-container *ngIf="category.max_age === maxAge">
                      {{ 'economy.all_ages' | translate }}
                    </ng-container>
                    <ng-container *ngIf="category.max_age !== maxAge">
                      {{ 'economy.under_age' | translate : { age: category.max_age + 1 } }}
                    </ng-container>
                  </p>
                  <p *ngIf="category.allowed_group_type" [ngSwitch]="category.allowed_group_type">
                    <ng-container *ngSwitchCase="GroupType.AssociateMembers">
                      {{ 'economy.for_group_type' | translate }} {{ 'group.associate_members' | translate }}
                    </ng-container>
                  </p>
                  <p *ngIf="category.allowed_user_type | isNumber" [ngSwitch]="category.allowed_user_type">
                    <ng-container *ngSwitchCase="0">
                      {{ 'economy.for_group_type' | translate }} {{ 'user_types.admins' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                      {{ 'economy.for_group_type' | translate }} {{ 'user_types.players' | translate }}
                    </ng-container>
                  </p>
                </div>
                <div class="ns-category-price-description">
                  <p
                    [innerHTML]="
                      'economy.including_transaction_fee' | translate : { fee: category.total_fee | currency }
                    "
                  ></p>
                  <p>
                    {{ 'economy.net_amount' | translate }}: <span>{{ category.net_amount | currency }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p class="ns-divider ns-subtitle" [class.ns-disabled]="priceCategories && priceCategories.length > 1">
            {{ 'miscellaneous.or' | translate }}
          </p>

          <app-button
            *ngIf="!isSettingCustomPrice"
            theme="dark"
            class="ns-set-custom-price"
            (click)="toggleCustomPrice(true)"
          >
            + {{ 'payment.set_custom_price' | translate }}
          </app-button>

          <ng-container *ngIf="isSettingCustomPrice">
            <div class="ns-custom-price-header" [class.ns-disabled]="priceCategories && priceCategories.length > 1">
              <span class="ns-subtitle">{{ 'payment.custom_price' | translate }}</span>
              <button
                class="ns-close-custom-price"
                mat-icon-button
                [disabled]="priceCategories.length > 1"
                (click)="toggleCustomPrice(false)"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <mat-form-field>
              <mat-label>{{ 'economy.net_amount' | translate }}</mat-label>
              <input matInput type="number" formControlName="customPrice" autocomplete="off" />
            </mat-form-field>
          </ng-container>
        </app-expand-y>
      </div>

      <ng-container *ngIf="paymentType !== PaymentTypes.MembershipFee">
        <mat-form-field>
          <mat-label>{{ 'economy.net_amount' | translate }}</mat-label>
          <input matInput type="number" formControlName="netAmount" autocomplete="off" />
        </mat-form-field>
      </ng-container>

      <mat-form-field>
        <mat-label>{{ 'economy.payment_note' | translate }}</mat-label>
        <textarea
          matInput
          rows="3"
          formControlName="note"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
        ></textarea>
        <mat-hint>{{ 'economy.payment_note_invoice_hint' | translate }}</mat-hint>
      </mat-form-field>
    </form>
  </div>
</app-overlay>
