import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { Store } from '@ngxs/store'
import { finalize } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { Table } from '../../../../../core/classes/table/table'
import { UserResponse } from '../../../../../core/interfaces/user/user-response'
import { ReminderHistoryResponse } from './interfaces/reminder-history-response'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { ToggleUserProfile } from 'src/app/shared/modules/user-profile/state/user-profile.actions'

@Component({
  selector: 'app-reminder-history',
  templateUrl: './reminder-history.component.html',
  styleUrls: ['./reminder-history.component.scss'],
  host: { '[@reminderHistoryAnimation]': '' },
  animations: [trigger('reminderHistoryAnimation', OverlayAnimations.detailsPopup)]
})
export class ReminderHistoryComponent implements OnInit {
  @Input() user: Partial<UserResponse>
  @Input() paymentName: string
  @Input() userPaymentId: number
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<ReminderHistoryResponse> = new Table()

  get Columns() {
    return Columns
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  constructor(
    private store: Store,
    private economyTabService: EconomyTabService
  ) {}

  ngOnInit() {
    this.table.columns = [
      Columns.Receiver,
      Columns.Reminder,
      Columns.Date,
      Columns.Email,
      Columns.PushNotification,
      Columns.SMS
    ]
    this.getReminderHistory()
  }

  private getReminderHistory(): void {
    this.table.loading = true
    this.economyTabService
      .getUserPaymentReminders(this.userPaymentId)
      .pipe(finalize(() => (this.table.loading = false)))
      .subscribe((response) => (this.table.data = response))
  }

  openUserProfile(id: number): void {
    this.store.dispatch(new ToggleUserProfile(true, id))
  }

  onClose(shouldSendNewReminder: boolean): void {
    this.close.emit(shouldSendNewReminder)
  }
}
