import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { finalize } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { UserResponse } from '../../../../../core/interfaces/user/user-response'
import { SnackbarService } from '../../../../../shared/components/snackbar/snackbar.service'
import { SharedModule } from '../../../../../shared/modules/shared.module'
import { SMSService } from '../../../../../core/api/sms.service'
import { SMSStatus } from '../../../../../core/enums/organization/sms-status'
import { Constants } from '../../../../../constants/constants'
import { Modal } from '../../../../../core/classes/global/modal'

@Component({
  selector: 'app-new-reminder',
  templateUrl: './new-reminder.component.html',
  styleUrls: ['./new-reminder.component.scss'],
  standalone: true,
  imports: [SharedModule],
  animations: []
})
export class NewReminderComponent implements OnInit {
  @Input() user: Partial<UserResponse>
  @Input() userPaymentId: number

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  loading: boolean = false
  notifyBySMSField: FormControl = new FormControl(false)
  messageField: FormControl = new FormControl('', Validators.maxLength(Constants.maxCharactersInPushNotification))
  smsStatus: SMSStatus | null = null
  notEnoughCreditsModal: Modal = new Modal()

  get cost(): number {
    return 1 + Math.ceil(this.messageField.value.length / Constants.smsCharactersPer1Credit)
  }

  get Constants() {
    return Constants
  }

  get SMSStatus() {
    return SMSStatus
  }

  constructor(
    private translate: TranslateService,
    private economyTabService: EconomyTabService,
    private smsService: SMSService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.loading = true
    this.smsService
      .getSMSStatusForPaymentReminders(this.userPaymentId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => {
        this.smsStatus = response.payment_reminder

        if (this.smsStatus === SMSStatus.NotEnoughCredits) {
          this.notifyBySMSField.disable()
        }
      })
  }

  sendReminder(): void {
    this.loading = true
    const payload: { dunning_message: string; send_sms_notification?: boolean } = {
      dunning_message: this.messageField.value
    }

    if (this.smsStatus === SMSStatus.Allowed) {
      payload.send_sms_notification = this.notifyBySMSField.value
    }

    this.economyTabService
      .sendReminder(this.userPaymentId, payload)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.close.emit(true)
          this.snackbarService.success(
            this.translate.instant('economy.new_reminder_success', {
              name: `${this.user.first_name} ${this.user.last_name}`
            })
          )
        },
        error: (e) => {
          if (e.error.code === 'sms__not_enough_credits') {
            this.notEnoughCreditsModal.open()
            this.smsStatus = SMSStatus.NotEnoughCredits
            this.notifyBySMSField.patchValue(false)
            this.notifyBySMSField.disable()
          }
        }
      })
  }

  onClose(): void {
    this.close.emit(false)
  }
}
